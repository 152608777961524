// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-de-js": () => import("./../../../src/pages/blog/index.de.js" /* webpackChunkName: "component---src-pages-blog-index-de-js" */),
  "component---src-pages-blog-index-en-js": () => import("./../../../src/pages/blog/index.en.js" /* webpackChunkName: "component---src-pages-blog-index-en-js" */),
  "component---src-pages-blog-index-es-js": () => import("./../../../src/pages/blog/index.es.js" /* webpackChunkName: "component---src-pages-blog-index-es-js" */),
  "component---src-pages-blog-index-fr-js": () => import("./../../../src/pages/blog/index.fr.js" /* webpackChunkName: "component---src-pages-blog-index-fr-js" */),
  "component---src-pages-blog-index-nl-js": () => import("./../../../src/pages/blog/index.nl.js" /* webpackChunkName: "component---src-pages-blog-index-nl-js" */),
  "component---src-pages-blog-index-pl-js": () => import("./../../../src/pages/blog/index.pl.js" /* webpackChunkName: "component---src-pages-blog-index-pl-js" */),
  "component---src-pages-cases-index-de-js": () => import("./../../../src/pages/cases/index.de.js" /* webpackChunkName: "component---src-pages-cases-index-de-js" */),
  "component---src-pages-cases-index-en-js": () => import("./../../../src/pages/cases/index.en.js" /* webpackChunkName: "component---src-pages-cases-index-en-js" */),
  "component---src-pages-cases-index-es-js": () => import("./../../../src/pages/cases/index.es.js" /* webpackChunkName: "component---src-pages-cases-index-es-js" */),
  "component---src-pages-cases-index-fr-js": () => import("./../../../src/pages/cases/index.fr.js" /* webpackChunkName: "component---src-pages-cases-index-fr-js" */),
  "component---src-pages-cases-index-nl-js": () => import("./../../../src/pages/cases/index.nl.js" /* webpackChunkName: "component---src-pages-cases-index-nl-js" */),
  "component---src-pages-cases-index-pl-js": () => import("./../../../src/pages/cases/index.pl.js" /* webpackChunkName: "component---src-pages-cases-index-pl-js" */),
  "component---src-pages-guide-index-de-js": () => import("./../../../src/pages/guide/index.de.js" /* webpackChunkName: "component---src-pages-guide-index-de-js" */),
  "component---src-pages-guide-index-en-js": () => import("./../../../src/pages/guide/index.en.js" /* webpackChunkName: "component---src-pages-guide-index-en-js" */),
  "component---src-pages-guide-index-es-js": () => import("./../../../src/pages/guide/index.es.js" /* webpackChunkName: "component---src-pages-guide-index-es-js" */),
  "component---src-pages-guide-index-fr-js": () => import("./../../../src/pages/guide/index.fr.js" /* webpackChunkName: "component---src-pages-guide-index-fr-js" */),
  "component---src-pages-guide-index-nl-js": () => import("./../../../src/pages/guide/index.nl.js" /* webpackChunkName: "component---src-pages-guide-index-nl-js" */),
  "component---src-pages-guide-index-pl-js": () => import("./../../../src/pages/guide/index.pl.js" /* webpackChunkName: "component---src-pages-guide-index-pl-js" */),
  "component---src-pages-sports-index-de-js": () => import("./../../../src/pages/sports/index.de.js" /* webpackChunkName: "component---src-pages-sports-index-de-js" */),
  "component---src-pages-sports-index-en-js": () => import("./../../../src/pages/sports/index.en.js" /* webpackChunkName: "component---src-pages-sports-index-en-js" */),
  "component---src-pages-sports-index-es-js": () => import("./../../../src/pages/sports/index.es.js" /* webpackChunkName: "component---src-pages-sports-index-es-js" */),
  "component---src-pages-sports-index-fr-js": () => import("./../../../src/pages/sports/index.fr.js" /* webpackChunkName: "component---src-pages-sports-index-fr-js" */),
  "component---src-pages-sports-index-nl-js": () => import("./../../../src/pages/sports/index.nl.js" /* webpackChunkName: "component---src-pages-sports-index-nl-js" */),
  "component---src-pages-sports-index-pl-js": () => import("./../../../src/pages/sports/index.pl.js" /* webpackChunkName: "component---src-pages-sports-index-pl-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-page-de-js": () => import("./../../../src/templates/case-page-de.js" /* webpackChunkName: "component---src-templates-case-page-de-js" */),
  "component---src-templates-case-page-en-js": () => import("./../../../src/templates/case-page-en.js" /* webpackChunkName: "component---src-templates-case-page-en-js" */),
  "component---src-templates-case-page-es-js": () => import("./../../../src/templates/case-page-es.js" /* webpackChunkName: "component---src-templates-case-page-es-js" */),
  "component---src-templates-case-page-fr-js": () => import("./../../../src/templates/case-page-fr.js" /* webpackChunkName: "component---src-templates-case-page-fr-js" */),
  "component---src-templates-case-page-nl-js": () => import("./../../../src/templates/case-page-nl.js" /* webpackChunkName: "component---src-templates-case-page-nl-js" */),
  "component---src-templates-case-page-pl-js": () => import("./../../../src/templates/case-page-pl.js" /* webpackChunkName: "component---src-templates-case-page-pl-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-index-page-de-js": () => import("./../../../src/templates/index-page-de.js" /* webpackChunkName: "component---src-templates-index-page-de-js" */),
  "component---src-templates-index-page-en-js": () => import("./../../../src/templates/index-page-en.js" /* webpackChunkName: "component---src-templates-index-page-en-js" */),
  "component---src-templates-index-page-es-js": () => import("./../../../src/templates/index-page-es.js" /* webpackChunkName: "component---src-templates-index-page-es-js" */),
  "component---src-templates-index-page-fr-js": () => import("./../../../src/templates/index-page-fr.js" /* webpackChunkName: "component---src-templates-index-page-fr-js" */),
  "component---src-templates-index-page-nl-js": () => import("./../../../src/templates/index-page-nl.js" /* webpackChunkName: "component---src-templates-index-page-nl-js" */),
  "component---src-templates-index-page-pl-js": () => import("./../../../src/templates/index-page-pl.js" /* webpackChunkName: "component---src-templates-index-page-pl-js" */),
  "component---src-templates-promo-page-js": () => import("./../../../src/templates/promo-page.js" /* webpackChunkName: "component---src-templates-promo-page-js" */),
  "component---src-templates-sport-page-de-js": () => import("./../../../src/templates/sport-page-de.js" /* webpackChunkName: "component---src-templates-sport-page-de-js" */),
  "component---src-templates-sport-page-en-js": () => import("./../../../src/templates/sport-page-en.js" /* webpackChunkName: "component---src-templates-sport-page-en-js" */),
  "component---src-templates-sport-page-es-js": () => import("./../../../src/templates/sport-page-es.js" /* webpackChunkName: "component---src-templates-sport-page-es-js" */),
  "component---src-templates-sport-page-fr-js": () => import("./../../../src/templates/sport-page-fr.js" /* webpackChunkName: "component---src-templates-sport-page-fr-js" */),
  "component---src-templates-sport-page-nl-js": () => import("./../../../src/templates/sport-page-nl.js" /* webpackChunkName: "component---src-templates-sport-page-nl-js" */),
  "component---src-templates-sport-page-pl-js": () => import("./../../../src/templates/sport-page-pl.js" /* webpackChunkName: "component---src-templates-sport-page-pl-js" */),
  "component---src-templates-terms-page-js": () => import("./../../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

